<template>
  <v-btn v-if="showButton" color="primary" @click="handlePreview" text>
    {{ $t("FORMS.preview") }}
  </v-btn>
</template>

<script>
import SiteService from "@/core/services/site.service.js";
import ApiService from "@/core/services/api.service";

export default {
  name: "PreviewURLComponent",
  props: ["lang", "type", "slug", "formModel"],
  data() {
    return {
      settings: null,
      url: null,
    };
  },
  computed: {
    showButton() {
      return (
        !!process.env[
          "VUE_APP_PAGE_BY_ID_" + SiteService.getActiveSiteId() + "_URL"
        ] && !!this.formModel.id
      );
    },
  },
  methods: {
    fetchSettings() {
      return new Promise((resolve) => {
        if (this.type != "page") {
          ApiService.get(
            this.type + "/admin/" + SiteService.getActiveSiteId() + "/setting"
          )
            .then(({ data }) => {
              this.settings = [...data];
              resolve(data);
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.settings = null;
          resolve();
        }
      });
    },

    handlePreview() {
      let URL = "";
      this.fetchSettings().then(() => {
        ApiService.get("site/" + SiteService.getActiveSiteId())
          .then(({ data }) => {
            URL =
              process.env[
                "VUE_APP_PAGE_BY_ID_" + SiteService.getActiveSiteId() + "_URL"
              ];

            let locale = "";

            if (
              data &&
              data.custom_fields &&
              data.custom_fields.selectedLanguages &&
              data.custom_fields.selectedLanguages.length
            ) {
              locale = data.custom_fields.selectedLanguages[0];
              if (locale != this.lang) {
                URL += this.lang + "/";
              }
              // let settings = this.settings;
              // console.log(settings);
              // console.log(typeof settings);
              // debugger;
              if (this.type != "page") {
                let setting = this.settings.find(
                  (item) => item.id == this.type
                );

                if (
                  setting &&
                  setting.setting_content.url_prefix &&
                  setting.setting_content.url_prefix[this.lang]
                )
                  URL += setting.setting_content.url_prefix[this.lang] + "/";
              }
              if (
                this.formModel &&
                this.formModel.translations &&
                this.formModel.translations[this.lang] &&
                this.formModel.translations[this.lang].slug
              ) {
                URL += this.formModel.translations[this.lang].slug;
              }
            }

            // window.open(URL, "_blank");
            this.$emit("handlePreview", URL);
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      });
    },
  },

  mounted() {},
};
</script>


