<template>
  <v-snackbar
    v-model="snackbarInfo.active"
    auto-height
    color="green"
    :timeout="snackbarInfo.timeout"
    :top="true"
  >
    <v-layout align-center pr-4>
      <v-icon class="pr-3" dark large>check_circle</v-icon>
      <v-layout column>
        <div>
          <strong>{{ Info }}</strong>
        </div>
        <div style="font-size: 1.25rem">
          <b>{{ snackbarInfo.text }}</b>
        </div>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnakBarInfoComponent",
  props: ["snackbarInfo"],
};
</script>
